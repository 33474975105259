import React from "react";
import { getImage } from "../../util";

export const OrderItem = ({ prod }) => {
  const { product: mainProd } = prod;
  const image = prod?.style
    ? mainProd?.styles?.find((st) => st.name === prod?.style)?.url
    : prod?.gallery[0];

  const priceSelect = () => {
    if (
      (prod?.bottomSizing && prod?.topSizing) ||
      (!prod?.bottomSizing && !prod?.topSizing)
    ) {
      return prod?.price;
    } else if (prod?.topSizing) {
      return prod?.topPrice;
    } else {
      return prod?.bottomPrice;
    }
  };
  const sizeType =
    prod?.topSizing && prod?.bottomSizing
      ? "full"
      : prod?.topSizing
      ? "top"
      : "bottom";

  const price = prod?.haveDiscount
    ? parseFloat(
        priceSelect() - (priceSelect() * prod?.discount) / 100
      ).toFixed(2)
    : parseFloat(priceSelect() || 0.0).toFixed(2);

  return (
    <div className="col-md-6 col-sm-12 my-2">
      <div className="d-flex">
        <img
          src={getImage(image)}
          alt="product"
          width="200"
          className="me-3"
          style={{ aspectRatio: "5/7", objectFit: "cover" }}
        />
        <div className="product_content">
          <div className="title">
            <h5>{prod?.name}</h5>
          </div>
          {prod?.style && <p>Style: {prod?.style}</p>}
          <p>Quantity: {prod?.quantity}</p>
          {prod?.bottomSizing && (
            <p>
              Sizes: {prod?.bottomSizing}
              {prod?.isBottomPre && " (Pre-Order)"}/{prod?.topSizing}
              {prod?.isTopPre && " (Pre-Order)"}
            </p>
          )}
          <p>
            Price:{" "}
            {prod?.haveDiscount ? (
              <span style={{ color: "#F05957" }}>
                {(
                  parseFloat(price).toFixed(2) *
                  (prod?.isFreePoolItem
                    ? prod?.quantity - (prod.bogoQuantity || 0)
                    : prod?.quantity)
                ).toFixed(2)}{" "}
                USD
                <span
                  style={{
                    fontStyle: "italic",
                    marginLeft: "5px",
                    color: "#3d3d3d",
                  }}
                >
                  <strike>
                    {parseFloat(
                      priceSelect() *
                        (prod?.isFreePoolItem
                          ? prod?.quantity - (prod.bogoQuantity || 0)
                          : prod?.quantity)
                    ).toFixed(2)}{" "}
                    USD
                  </strike>
                </span>
              </span>
            ) : (
              <span>
                {parseFloat(
                  price *
                    (prod?.isFreePoolItem
                      ? prod?.quantity - (prod.bogoQuantity || 0)
                      : prod?.quantity)
                ).toFixed(2)}{" "}
                USD
              </span>
            )}
          </p>
          {!mainProd?.isAccessories && (
            <div className="d-flex align-items-center">
              Color:
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: "400",
                  marginLeft: "5px",
                }}
              >
                {sizeType === "full"
                  ? prod.topColorName === prod.bottomColorName
                    ? prod.bottomColorName
                    : `${prod.topColorName + ", " + prod.bottomColorName}`
                  : ""}
                {sizeType === "top" && (prod.topColorName || "White")}
                {sizeType === "bottom" && (prod.bottomColorName || "White")}
              </p>
            </div>
          )}
          <p style={{ color: "red", fontSize: "13px" }}>
            {prod?.isPreOrderItem && "*Pre-Order"}
          </p>
          {prod?.haveDiscount && (
            <p
              style={{
                margin: "0",
                background: "#F05957",
                borderRadius: "8px",
                color: "#fff",
                padding: "2px 6px",
                display: "inline-block",
              }}
            >
              -{prod?.discount}%
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
