import { useMutation } from "@apollo/react-hooks";
import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { USER_LOGOUT } from "../../graphql/modules/auth";
import {
  clearAllItemFromCart,
  LOGIN,
  logoutUser,
  onRediretMobile,
} from "../../store/modules";
import { SHOPPING_ID } from "../../util";

const PrivateMenu = ({ toLogin = false }) => {
  const token = useSelector((state) => state.auth.jwtToken);
  const dispatch = useDispatch();
  // logout function
  const [LogoutMutaion, { loading }] = useMutation(USER_LOGOUT, {
    variables: {
      code: token,
    },
  });
  const onLogout = async () => {
    try {
      const {
        data: { Logout },
      } = await LogoutMutaion();
      if (Logout.success) {
        dispatch(logoutUser());
        dispatch(clearAllItemFromCart());
      } else {
        dispatch(logoutUser());
        dispatch(clearAllItemFromCart());
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // redirect
  const onGoToAuth = () => {
    dispatch(onRediretMobile({ type: LOGIN }));
  };

  return (
    <Spin spinning={loading}>
      <div className="shopping-cart-list">
        <div className="shopping-cart-sidebar">
          {!toLogin ? (
            <ul>
              <li style={{ cursor: "pointer" }}>
                <span>
                  <i className="fas fa-heart"></i>
                </span>
                &nbsp;
                <Link to="/welcome-board?tab=wishlist">Wishlist</Link>
              </li>
              <li style={{ cursor: "pointer" }}>
                <span>
                  <i className="fas fa-shopping-bag"></i>
                </span>
                &nbsp;
                <Link to="/welcome-board?tab=orders">Orders</Link>
              </li>
              <li style={{ cursor: "pointer" }}>
                <span>
                  <i className="fas fa-cog"></i>
                </span>
                &nbsp;
                <Link to="/welcome-board?tab=overview">Account</Link>
              </li>
              <li style={{ cursor: "pointer" }}>
                <span>
                  <i className="fas fa-user"></i>
                </span>
                &nbsp;
                <button
                  style={{
                    border: "none",
                    background: "none",
                    padding: "0 0 0 5px",
                  }}
                  onClick={onLogout}
                >
                  Log out
                </button>
              </li>
            </ul>
          ) : (
            <ul>
              <li style={{ cursor: "pointer" }}>
                <span>
                  <i style={{ color: "#3d3d3d" }} className="fas fa-user"></i>
                </span>
                &nbsp;
                <button
                  style={{
                    border: "none",
                    background: "none",
                    padding: "0 0 0 5px",
                    color: "#3d3d3d",
                  }}
                  onClick={onGoToAuth}
                  id={SHOPPING_ID}
                >
                  Signin
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default PrivateMenu;
