export const totalOrderPrice = (data = [], couponDiscount = 0, cp = false) => {
  let regularProdPrice = 0;
  let discountProdPrice = 0;
  let discountedItems = 0;

  for (let i = 0; i < data?.length; i++) {
    const product = data[i];

    if (product.haveDiscount) {
      discountProdPrice = discountProdPrice + product.totalDiscountedPrice;
    } else {
      discountedItems++;
      regularProdPrice = regularProdPrice + product.totalDiscountedPrice;
    }
  }
  let discountAmount = 0;
  if (couponDiscount > 0) {
    discountAmount = ((regularProdPrice * couponDiscount) / 100).toFixed(2);
    regularProdPrice = regularProdPrice - discountAmount;
  }

  if (cp) {
    return [
      discountProdPrice + regularProdPrice,
      discountAmount,
      discountedItems === data.length && couponDiscount,
    ];
  }

  return discountProdPrice + regularProdPrice;
};

export const calculateDiscountOnTotalPrice = (
  data = [],
  discountSourceParams = []
) => {
  let discountSource = [...discountSourceParams];
  discountSource.sort((a, b) => b?.amount - a?.amount);
  let grandTotal = 0;
  for (let i = 0; i < data?.length; i++) {
    const product = data[i];
    grandTotal = grandTotal + (product?.totalDiscountedPrice || 0);
  }
  let discountAmount = 0;
  for (let i = 0; i < discountSource?.length; i++) {
    if (
      discountSource[i].status === "Active" &&
      discountSource[i].validUntil > Date.now() &&
      discountSource[i].amount <= grandTotal
    ) {
      discountAmount = discountSource[i].discountAmount;
      break;
    }
  }
  return discountAmount;
};
