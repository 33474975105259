import gql from "graphql-tag";
import { CORE_ELEMENT_PRODUCT } from "./fragment";

export const FETCH_HOME_PAGE_DETAILS = gql`
  query FetchHomePageProducts($type: String) {
    FetchHomePageProducts(type: $type) {
      code
      success
      message
      result {
        product {
          _id
          name
          description
          price
          defaultImg
          gallery
          haveDiscount
          discount
        }
        isDefault
        type
        categoryTitle
      }
    }
  }
`;

export const FETCH_BANNER = gql`
  query FetchBanner {
    FetchBanner {
      code
      success
      message
      result {
        _id
        link
        type
        anchorLink
        bannerText
        isBottomPoster
        isFirstBottomPoster
        firstBtnLink
        firstBtnText
        secondBtnLink
        secondBtnText
        isMobileItem
        type
      }
    }
  }
`;

export const FETCH_SECTION_FOUR = gql`
  query FetchHomePageSectionFour {
    FetchHomePageSectionFour {
      code
      success
      message
      result {
        sectionTitle
        sectionDescription
        photo
        buttonLink
        buttonText
      }
    }
  }
`;

export const FETCH_CUSTOMER_PICK = gql`
  query FetchCustomerPicks {
    FetchCustomerPicks {
      code
      success
      message
      sectionTitle
      result {
        _id
        link
        pickLink
        userName
      }
    }
  }
`;

export const FETCH_HOMEPAGE_SECTION = gql`
  ${CORE_ELEMENT_PRODUCT}
  query FetchHomepageSectionsWithProduct($productSize: Int) {
    FetchHomepageSectionsWithProduct(productSize: $productSize) {
      code
      result {
        category {
          _id
          name
          sizeType
        }
        subcategory {
          _id
          name
          sizeType
        }
        isCategory
        sectionTitle
        categoryBackground
        type
        sizeType
        product {
          ...CoreProductElement
        }
      }
    }
  }
`;

export const FETCH_HOME_SECTION_SEQUENCE = gql`
  query FetchHomePageSectionsSequence {
    FetchHomePageSectionsSequence {
      code
      success
      result {
        _id
        name
      }
    }
  }
`;

export const FETCH_PRODUCT_HOME_PAGE = gql`
  query FetchCategoryWithRendomProducts(
    $limit: Int
    $offset: Int
    $productSize: Int
  ) {
    FetchCategoryWithRendomProducts(
      limit: $limit
      offset: $offset
      productSize: $productSize
    ) {
      code
      success
      message
      result {
        _id
        name
        product {
          _id
          name
          defaultImg
          price
          gallery
          haveDiscount
          discount
        }
      }
    }
  }
`;

export const GLOBAL_SEARCH = gql`
  query FetchGlobalSearchProduct($searchTxt: String) {
    FetchGlobalSearchProduct(searchTxt: $searchTxt) {
      code
      message
      success
      subcategories {
        _id
        name
        category {
          _id
          name
        }
      }
      categories {
        _id
        name
      }
      products {
        _id
        name
        category {
          _id
          name
        }
        subcategory {
          _id
          name
        }
      }
    }
  }
`;

export const ADD_NEW_SUBSCRIBER = gql`
  mutation AddSubscriber($email: String!) {
    AddSubscriber(email: $email) {
      code
      message
      success
    }
  }
`;
export const APP_SETTINGS_QUERY = gql`
  query FetchAppSettings {
    FetchAppSettings {
      isInMaintenance
      isImagedCategory
      announcement {
        msg
        isOn
      }
      buyOneGetOne {
        reference
        discountOnCategory
        isOn
        hasLimit
        maxLimit
        rewardThreshold
      }
    }
  }
`;

export const MAINTENANCE_SUBSCRIPTION = gql`
  subscription {
    MaintenanceSub {
      isInMaintenance
    }
  }
`;
// export const CART_QUANTITY_LIMIT = gql`
//   subscription {
//     CartQuantityLimitSub {
//       cartQuantityLimit
//     }
//   }
// `;

export const ANNOUNCEMENT_SUBSCRIPTION = gql`
  subscription {
    AnnouncementSub {
      isOn
      msg
    }
  }
`;

export const FETCH_FEATURE_CATEOGRY = gql`
  query FetchFeaturedCategory {
    FetchFeaturedCategory {
      success
      message
      result {
        _id
        sectionTitle
        imageLink
        url
        cardTitle
      }
    }
  }
`;

export const FETCH_ABOUT_CATEGORY = gql`
  query FetchAbout($limit: Int, $offset: Int) {
    FetchAbout(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        aboutItems {
          _id
          name
          mediaLinkWeb
          mediaLinkMobile
          position
        }
      }
    }
  }
`;
